@use '~/src/sass/abstracts/variables/colors';
@use '~/src/sass/abstracts/variables/structure';
@use '~/src/sass/abstracts/utils';

.cards-list__cards {
	display: grid;
	grid-template-columns: minmax(1rem, 100%);
	grid-gap: 3.2rem;

	&:not(:last-child) {
		margin-bottom: 3.2rem;
	}

	.card__excerpt {
		top: 9.5rem;
		right: 3rem;
		bottom: 3rem;
	}
}

.card {
	position: relative;
	display: flex;
	flex: 1 1 0;
	flex-direction: column;
	padding: 0;
	color: colors.$white;
	background-color: colors.$green;
	border-radius: 20px;

	&__image {
		width: 100%;
		height: 100%;
		opacity: 1;
		transition: opacity 500ms ease-in-out;

		img {
			object-fit: cover;
			min-width: 100%;
			height: 28rem;
			border-radius: 20px;
		}
	}

	&__overlay {
		position: absolute;
		inset: 0;
		background-color: colors.$grey-400;
		border-radius: 20px;
		opacity: 0.3;
		transition: background-color 500ms ease-in-out,
			opacity 500ms ease-in-out;
	}

	&__link {
		position: absolute;
		top: 3rem;
		right: 3rem;
		z-index: 3;
		display: flex;
		align-items: center;
		justify-content: center;
		width: 3.2rem;
		height: 3.2rem;
		text-decoration: none;
		border: 1px solid colors.$white;
		border-radius: 100%;
		transition: background-color 500ms ease-in-out,
			color 500ms ease-in-out;

		i {
			font-size: 1rem;
			color: colors.$white;
		}
	}

	&__content {
		position: absolute;
		bottom: 3rem;
		left: 3rem;
		z-index: 3;
		max-width: 75%;
		margin-bottom: 0;
		font-weight: 900;

		@extend %font-lg;
	}

	&__title {
		text-transform: uppercase;
	}

	&__icon {
		position: absolute;
		top: 3rem;
		left: 3rem;
		z-index: 3;
		font-size: 3.2rem;
		font-weight: 300;
		opacity: 1;
		transition: opacity 500ms ease-in-out;
	}

	&__excerpt {
		position: absolute;
		top: 3rem;
		left: 3rem;
		z-index: 3;
		max-width: 75%;
		opacity: 0;
		transition: opacity 500ms ease-in-out;

		p {
			@extend %font-s;

			&:last-child {
				margin-bottom: 0;
			}
		}
	}
}

.card:hover {
	.card__overlay {
		background-color: var(--card-color);
		opacity: 1;
	}

	.card__content {
		opacity: 0;
	}

	.card__link {
		background-color: colors.$white;

		i {
			color: var(--card-color);
		}
	}

	.card__excerpt {
		opacity: 1;
	}
}

@media screen and (min-width: structure.$tablet-landscape) {
	.cards-list__cards {
		&--1 {
			grid-template-columns: minmax(1rem, 100%);

			.card__content {
				transition: top 500ms ease-in-out, transform 500ms ease-in-out;
			}

			.card__excerpt {
				top: 50%;
				left: 50%;
				max-width: 40%;
				transform: translateY(-50%);
			}

			.card:hover {
				.card__content {
					top: 50%;
					max-width: 40%;
					opacity: 0.25;
					transform: translateY(-50%);
				}
			}
		}

		&--2 {
			grid-template-columns: repeat(2, minmax(1rem, 1fr));

			.card:hover {
				.card__icon {
					opacity: 0;
				}
			}
		}

		&--3 {
			.card:hover {
				.card__content {
					opacity: 0;
				}
			}
		}

		&--multiple-col {
			display: grid;
			grid-template-columns: repeat(6, minmax(1rem, 1fr));

			.card:nth-child(5n + 1) {
				grid-column: 1 / 4;
			}

			.card:nth-child(5n + 2) {
				grid-column: 4 / 7;
			}

			.card:nth-child(5n + 3) {
				grid-column: 1 / 3;
			}

			.card:nth-child(5n + 4) {
				grid-column: 3 / 5;
			}

			.card:nth-child(5n + 5) {
				grid-column: 5 / 7;
			}

			.card:nth-child(5n + 3),
			.card:nth-child(5n + 4),
			.card:nth-child(5n + 5) {
				&:hover {
					.card__content {
						opacity: 0;
					}
				}
			}
		}
	}
}

@media screen and (min-width: structure.$desktop) {
	.cards-list__cards {
		&--3 {
			grid-template-columns: repeat(3, minmax(1rem, 1fr));
		}
	}
}
